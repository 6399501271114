'use client'
import { LoadingContext } from "@/context/tools/loading"
import { ToolsContext } from "@/context/tools/tools"
import { useEffectOnce } from "@/hooks/utils/useEffectOnce"
import { handleKeyRandom } from "@/utils/functions"
import parse from "html-react-parser"
import { useContext, useId, useMemo } from "react"
const LegalTable = ({ ...props }) => {
  const keyId = useId()
  return (
    <div style={{ marginBottom: `20px`, marginTop: `20px` }}>
      <table aria-label={props.aria} className="w-full text-sm text-left rtl:text-right text-black ">
        <tbody className="bg-white border-gray-700 justify-center text-center ">
          {props.data.map((x, i) => (
            <tr key={handleKeyRandom(keyId, i)} className="bg-gray-100 border-gray-300 border b-round text-center">
              <td className="font-bold text-black p-3 text-center  " align="right">
                {x.camp}
              </td>
              <td className="font-medium text-black p-3 text-start " align="right">
                {parse(x.text)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export const BodyLegal = ({ hostname }) => {
  const { mobile } = hostname
  const { tools: { texts }} = useContext(ToolsContext)
  const { modules } = useContext(LoadingContext)
  const privacyTexts = useMemo(() => texts.privacy_policy, [texts?.privacy_policy])
  const keyId = useId()

  useEffectOnce(() => {
    modules.handleLinkLoading(false)
  })

  return privacyTexts &&
    <div
      style={{
        minHeight: !mobile ? `95vh` : `100vh`,
        alignContent: `flex-start`,
      }}
    >
      <div size={{ xs: 12 }}>
        {privacyTexts?.purchase_terms && (
          <>
            {/* Purchase Terms Section */}
            <span className="font-bold text-[30px] text-center mb-2" style={{ fontSize: mobile ? `23px` : `30px` }}>
              {parse(privacyTexts.purchase_terms.title || ``)}
            </span>
            {privacyTexts.purchase_terms.text && (
              <span className="flex" >
                {parse(privacyTexts.purchase_terms.text)}
              </span>
            )}

            {/* Sections rendering using map */}
            {Object.entries(privacyTexts.purchase_terms).
              filter(([ , x ]) => x?.sequence !== undefined)?.
              sort(([ , a ], [ , b ]) => a.sequence - b.sequence)?.
              map(([ , sectionData ], idx) => {
                if (!sectionData) {
                  return null
                }

                return (
                  <div key={`section_${idx}`}>
                    {sectionData.title && (
                      <span className="font-bold flex mt-3 " style={{ fontSize: mobile ? `20px` : `30px` }} >
                        {parse(sectionData.title)}
                      </span>
                    )}
                    {sectionData.text && (Array.isArray(sectionData.text) ?
                      sectionData.text.map((x, i) => (
                        <span className="" key={handleKeyRandom(`text`, i)}>
                          {parse(x)}
                        </span>
                      )) :
                      <span >
                        {parse(sectionData.text)}
                      </span>
                    )}
                    {sectionData.data && (
                      <div>
                        <ul>
                          {sectionData.data.map((item, index) => {
                            if (Array.isArray(item)) {
                              return (
                                <ul key={`ul_${handleKeyRandom(keyId, index)}`} style={{ listStyle: `lower-latin` }}>
                                  {item.map((subItem, subIndex) => (
                                    <li key={`li_${handleKeyRandom(keyId, subIndex)}`}>
                                      <span >
                                        {parse(subItem)}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              )
                            }
                            return (
                              <li key={`li_${handleKeyRandom(keyId, index)}`}>
                                <span >
                                  {parse(item)}
                                </span>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                )
              })}
          </>
        )}

        {/* Privacy Section */}
        {privacyTexts?.title && (
          <span className="font-bold flex mt-2" style={{ fontSize: mobile ? `20px` : `30px` }}>
            {parse(privacyTexts.title)}
          </span>
        )}
        {privacyTexts?.text && (
          <span >
            {parse(privacyTexts.text)}
          </span>
        )}

        {/* Legal Tables Section */}
        {[ `owner`, `newsletter`, `account` ].map(section => {
          const sectionData = privacyTexts?.[section]
          if (!sectionData) {
            return null
          }

          return (
            <div className="mt-2" key={section}>
              <span className="font-bold flex mt-1" style={{ fontSize: mobile ? `15px` : `25px` }} >
                {parse(sectionData.title || ``)}
              </span>
              <span>
                {parse(sectionData.text || ``)}
              </span>
              {sectionData.data && (
                <LegalTable aria={`table-${section}`} data={sectionData.data} />
              )}
            </div>
          )
        })}

        {/* Treatment Section */}
        {privacyTexts?.treatment && (
          <div>
            <div className="font-bold flex mt-2" style={{ fontSize: mobile ? `17px` : `25px` }} >
              {parse(privacyTexts.treatment.title || ``)}
            </div>
            <div className="font-bold flex mt-2" style={{ fontSize: mobile ? `16px` : `20px` }} >
              {parse(privacyTexts.treatment.subtitle || ``)}
            </div>
            <div >
              {parse(privacyTexts.treatment.text || ``)}
            </div>
            {privacyTexts.treatment.list?.map((x, i) => (
              <div key={`box_${handleKeyRandom(keyId, i)}`}>
                <div className="mt-2 font-bold" >
                  {parse(x.subTitle || ``)}
                </div>
                {x.text?.map((b, e) => Array.isArray(b) ? (
                  <ul key={`ul_${handleKeyRandom(keyId, e)}`} style={{ listStyle: `lower-latin` }}>
                    {b.map((c, f) => (
                      <li key={`li_${handleKeyRandom(keyId, f)}`}>
                        <div >
                          {parse(c)}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div key={`txt_${handleKeyRandom(keyId, e)}`} >
                    {parse(b)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
}
