import { BodyLegal } from "@/app/_components/legal"
import { Modal } from "@/components/tailwind/Modal"

import { memo, useId } from "react"

import { TWButton } from "@/components/tailwind/Button" // Adjust the path as needed
import { MdClose } from "react-icons/md"

export const LegalModal = memo(({ open, setOpen, ...props }) => {
  const { colors, business } = props.hostname
  const handleClose = () => {
    setOpen(false)
  }

  const idModal = useId()

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      aria-described="alert-dialog-slide-description"
      id={idModal}
      className={` bg-white  p-6 relative `}
      style={{
        width: `120vh`,
        color: colors.color,
        height: `101vh`,
      }}
    >

      <div className="bg-transparent w-full p-2 mb-3">

        <TWButton {...{ colors }} className="absolute top-0 right-0 mt-3 mr-3 p-1" onClick={handleClose}>
          <MdClose className="size-6"/>
        </TWButton>

      </div>
      <div className="ms-1" style={{
        overflowY: `auto`,
        maxHeight: `calc(90vh - 50px)`,
      }}>

        <BodyLegal media={12} {...props} />
      </div>

    </Modal>
  )
})
